import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import {useState} from 'react'

export function InstructionDialog(props: { open: boolean, onClose: () => void }) {
  const backgroundColor = '#432'
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle style={{backgroundColor: backgroundColor, color: 'white'}}>How to play</DialogTitle>
      <DialogContent style={{backgroundColor: backgroundColor, color: 'white'}}>
        <Typography>
          <h2>Objective</h2>
          <p>Plant and grow chess pieces to capture the enemy King</p>

          <h2>Plants</h2>
          <p>Before moving, click on a vacant square adjacent to your King to plant a new piece</p>
          <ul>
            <li>Plants grow one step every turn</li>
            <li>A piece planted on a square with minerals (dots) will grow on its own</li>
            <li>A piece planted on a square with insufficient minerals will require your King
              to stay adjacent</li>
            <li>If your King runs out of minerals, it cannot help plants to grow</li>
            <li>Plants can be squashed by the opponent</li>
            <li>Your pieces can stand on your own plants safely, but their growth will pause</li>
            <li>The opponent will not know what you've planted until it is ready!</li>
          </ul>

          <h2>Pawns</h2>
          <p>Pawns double as mineral gatherers</p>
          <ul>
            <li>A pawn on a mineral square will harvest one mineral every turn</li>
            <li>Harvested minerals are stored on your King</li>
            <li>Minerals can be used to help plants grow</li>
            <li>Pawns do not promote; instead, any pawn that gets to the 8th rank siphons minerals from the enemy King!</li>
          </ul>

          <h2>Captures</h2>
          <p>When a piece is captured, it drops its cost in minerals on the captured square</p>

          <h2>Winning</h2>
          <p>If the opponent has no legal move, you win!</p>
          <p>(note, you do not need to be "checking" the King to win: even a stalemate counts as a win!)</p>
        </Typography>
      </DialogContent>
      <DialogActions style={{backgroundColor: backgroundColor, color: 'white'}}>
        <Button onClick={props.onClose} variant={'contained'} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export function InstructionButton() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return <>
    <Button variant={'contained'} onClick={handleClickOpen}>How to play</Button>
    <InstructionDialog open={open} onClose={handleClose} />
  </>
}
