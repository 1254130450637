import {AppBar, Box, Button, TextField, Toolbar, Typography} from '@mui/material'
import {useContext, useState} from 'react'
import {ProfileContext} from '../ProfileProvider'
import {GoogleLogin} from '@react-oauth/google'
import {jwtDecode} from 'jwt-decode'
import {InstructionButton} from './menu/InstructionDialog'
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js'
import {useNavigate} from 'react-router-dom'
import {createJoinCode} from '../repos/joincodeRepo'
import DiscordButton from './DiscordButton'

interface GoogleCredential {
  sub: string
  given_name: string
}

export function NameThingy({name, updateName}: {name: string, updateName: (name: string) => Promise<void>}) {
  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState(name)

  if (!editing) {
    return <>
      <Typography>Hello, </Typography>&nbsp;
      <Button variant='contained' onClick={() => setEditing(true)}>{value}</Button>
    </>
  }

  function accept() {
    setEditing(false)
    return updateName(value)
  }

  return <>
    <TextField value={value} onChange={e => setValue(e.target.value)} />
    <Button variant='contained' onClick={() => accept()}>Save</Button>
    <Button variant='contained' onClick={() => setEditing(false)}>Cancel</Button>
  </>
}

export function MyAppBar() {
  const {userInfo, linkGoogle, setUsername, currentProfile: profile} = useContext(ProfileContext)
  const appInsights = useAppInsightsContext()
  const navigate = useNavigate()

  function onLoginSuccess(credentialResponse: { credential?: string }) {
    const credential = credentialResponse.credential
    if (!credential) return
    const decoded = jwtDecode(credential) as GoogleCredential
    const sub = decoded.sub
    linkGoogle(sub)
  }

  function playLocal() {
    appInsights.trackEvent({name: 'play-local'})
    navigate('/local')
  }

  async function createNewGame() {
    return createJoinCode(profile.userId, userInfo.username)
  }

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="sticky">
        <Toolbar>
          <Box component="img" src={'/farm-chess-with-title.png'} onClick={() => navigate('/')}
               sx={{ height: '45px', cursor: 'pointer', m: 1}}/>
          {/*<Typography variant="h4" component="div" sx={{mr: 1, cursor: 'pointer'}} onClick={() => navigate('/')}>*/}
          {/*  Farm Chess*/}
          {/*</Typography>*/}
          <InstructionButton/>&nbsp;
          <Button variant={'contained'} onClick={createNewGame}>New game</Button>&nbsp;
          <Button variant={'contained'} onClick={playLocal}>Play locally</Button>&nbsp;
          <Button variant={'contained'} onClick={() => navigate('/dev')}>Dev Blog</Button>
          <span style={{flexGrow: 1}}></span> {/* Spacer */}
          <DiscordButton url={'https://discord.gg/zVux5Xemhe'}/>
          <NameThingy name={userInfo.username} updateName={setUsername}/>

          {!userInfo.linkedWithGoogle && <>
              <span style={{width: '1em'}}></span> {/* Spacer */}
              <GoogleLogin text={"continue_with"} onSuccess={onLoginSuccess}/>
              (optional)
          </>}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
