import React from 'react'
import {Button} from '@mui/material'
import {useNavigate} from 'react-router-dom'


interface LinkButtonProps {
  to: string
  children?: React.ReactNode
}

export const LinkButton = (props: LinkButtonProps) => {
  const { to, children } = props
  const navigate = useNavigate()

  return (
    <Button
      onClick={(event) => {
        event.preventDefault()
        navigate(to)
      }}
      variant={'contained'}
    >
      {children}
    </Button>
  )
}
