import {Plant, Square} from '../core/types'
import {plantCosts, sample, toRF, toSquare} from '../utils'
import {FarmChessPosition} from '../core/farmChessPosition'
import {useState} from 'react'
import {GameState} from '../components/GameHistory'

export function useChess() {
  const [history, setHistory] = useState<GameState[]>([{
    action: '(start)',
    position: new FarmChessPosition()
  }])

  const [selectedIndex, setSelectedIndex] = useState(0)
  const currentPosition = history[selectedIndex].position
  const isLive = selectedIndex === history.length - 1

  function move(from: Square, to: Square) {
    if (!currentPosition.isValidFarmChessMove(from, to)) return false
    const newPosition = currentPosition.move(from, to)

    const newAction = from + '-' + to
    const lastMove = history[history.length - 1].action

    if (lastMove.endsWith("/")) {
      setHistory([...history.slice(0, -1), {
        action: lastMove + newAction,
        position: newPosition
      }])
      return true
    }

    setHistory([...history, {
      action: newAction,
      position: newPosition
    }])
    setSelectedIndex(history.length)
    return true
  }

  function addPlant(plant: Plant) {
    const position = currentPosition.addPlant(plant)

    const newAction = "+" + plant.piece.toUpperCase() + toSquare(plant.rank, plant.file) + "/"

    const lastMove = history[history.length - 1].action
    if (lastMove.endsWith("/")) {
      setHistory([...history.slice(0, -1), {
        action: lastMove + newAction,
        position
      }])
      return
    }

    setHistory([...history, {
      action: newAction,
      position: position
    }])
    setSelectedIndex(history.length)
  }

  function moveRandom() {
    const moves = [...currentPosition.buildMoves()]
    if (moves.length === 0) {
      return false
    }
    const m = sample(moves)
    move(m.from, m.to)
    return true
  }

  function plantRandom() {
    const candidates: [number, number][] = []
    const [kingRank, kingFile] = toRF(currentPosition.findKing())
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        if (Math.abs(rank - kingRank) <= 1 && Math.abs(file - kingFile) <= 1 &&
          !currentPosition.get(toSquare(rank, file)) && !currentPosition.plants.find(plant => plant.rank === rank && plant.file === file)) {
          candidates.push([rank, file])
        }
      }
    }

    if (candidates.length === 0) return false

    const [rank, file] = sample(candidates)
    let keys = Object.keys(plantCosts)
    if (rank === 0 || rank === 7) keys = keys.filter(key => key !== "p")
    const piece = sample(keys) as keyof typeof plantCosts
    addPlant({color: currentPosition.turn, piece, rank, file, progress: 0})
    return true
  }

  return {
    boardStuff: {
      position: currentPosition,
      onDrop: move, onPlantAdded: addPlant,
    },
    historyStuff: {
      selectedIndex, onIndexSelected: setSelectedIndex,
      isLive,
      history, setHistory,
      back: () => setSelectedIndex(Math.max(0, selectedIndex - 1)),
      forward: () => setSelectedIndex(Math.min(history.length - 1, selectedIndex + 1)),
    },
    moveRandom, plantRandom
  }
}
