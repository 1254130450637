import {getUserProfile, updateUserInfo, UserProfile} from '../repos/userRepo'
import {v4} from 'uuid'
import {useEffect, useState} from 'react'
import {makeUsername} from '../usernameMaker'
import {useLocalStorageState} from './useLocalStorageState'

export function useUserRepo(): [UserProfile, (newProfile: UserProfile) => void] {

  const [userId, setUserId] = useLocalStorageState<string>('userId', () => 'random-' + v4())
  const [profile, setProfile] = useState<UserProfile>({userId})

  useEffect(() => {
    getUserProfile(userId).then(foundProfile => {
      if (foundProfile) {
        setProfile(foundProfile)
        return
      }
      const newInfo = {
        username: makeUsername()
      }
      setProfile({userId, info: newInfo})
      updateUserInfo(userId, newInfo)
    })
  }, [userId])

  const updateProfile = (newProfile: UserProfile) => {
    setUserId(newProfile.userId)
    setProfile(newProfile)

    if (newProfile.info) {
      return updateUserInfo(newProfile.userId, newProfile.info)
    }
  }

  return [profile, updateProfile]
}
