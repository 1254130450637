import React, {createContext} from 'react'
import {getUserProfile, UserInformation, UserProfile} from './repos/userRepo'
import {createProfile} from './hooks/useLocalStorageState'
import {useUserRepo} from './hooks/useUserRepo'

interface ProfileContextType {
  currentProfile: UserProfile
  userInfo: UserInformation
  setUsername: (username: string) => Promise<void>
  linkGoogle: (googleId: string) => Promise<void>
}

export const ProfileContext = createContext<ProfileContextType>((() => {
  const profile = createProfile()
  if (!profile.info) throw "no info!"
  return {
    currentProfile: profile,
    userInfo: profile.info,
    setUsername: async () => {
      console.log('setUsername not implemented')
    },
    linkGoogle: async () => {
      console.log('login not implemented')
    },
  }
})())


export function ProfileProvider({children}: { children: React.ReactNode }) {
  const [profile, updateProfile] = useUserRepo()
  if (!profile.info) return <div>Loading...</div>

  const linkGoogle = async (googleId: string) => {
    const existingProfile = await getUserProfile(googleId)
    if (existingProfile && existingProfile.info) {
      updateProfile({
        userId: googleId,
        info: {
          ...existingProfile.info,
          linkedWithGoogle: true
        }
      })
      return
    }
    if (!profile.info) return

    const newProfile: UserProfile = {
      userId: googleId,
      info: {
        ...profile.info,
        linkedWithGoogle: true
      }
    }
    updateProfile(newProfile)
  }

  async function setUsername(username: string) {
    console.log('ProfileProvider: setUsername', username)
    const newProfile = {
      ...profile,
      info: {
        ...profile.info,
        username
      }
    }
    updateProfile(newProfile)
  }

  return <ProfileContext.Provider
    value={{
      currentProfile: profile,
      userInfo: profile.info,
      setUsername,
      linkGoogle
    }}>
    {children}
  </ProfileContext.Provider>
}
