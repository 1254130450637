import {db} from '../firebaseConfig'
import {get, ref, set} from 'firebase/database'

export interface UserInformation {
  username: string
  linkedWithGoogle?: boolean
}

export interface UserProfile {
  userId: string
  info?: UserInformation
}

export async function getUserProfile(userId: string): Promise<UserProfile | null> {
  const userRef = ref(db, `users/${userId}`)
  const snapshot = await get(userRef)
  if (!snapshot.exists()) return null
  return {
    userId,
    info: snapshot.val() as UserInformation
  }
}

export async function updateUserInfo(userId: string, newInfo: UserInformation): Promise<void> {
  console.log('repo: updating', newInfo)
  const userRef = ref(db, `users/${userId}`)
  await set(userRef, newInfo)
}
