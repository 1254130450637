import {Chessboard} from "react-chessboard";
import {CustomSquareProps} from "react-chessboard/dist/chessboard/types";
import {useState} from "react";
import {Button} from "@mui/material";
import Piece from "@chessire/pieces";
import {plantCosts, playerIndex, toRF, toSquare} from "../../utils";
import {PlantOverlay} from "./PlantOverlay";
import {MineralOverlay} from "./MineralOverlay";
import {PlantDot} from "./PlantDot";
import {KingReservesOverlay} from "./KingReservesOverlay";
import {FarmChessPosition} from "../../core/farmChessPosition";
import {Color, Plant, Square} from "../../core/types";

export const defaultBoardWidth = 640

export default function FarmChessBoard(props: {
  position: FarmChessPosition,
  seat: Color | null,
  canPlay: boolean,
  onDrop?: (source: Square, target: Square) => boolean
  onPlantAdded?: (plant: Plant) => void
  width?: number,
  disableOverlays?: boolean,
}) {
  const [hoveredSquare, setHoveredSquare] = useState<Square | null>(null)
  const [plantingSquare, setPlantingSquare] = useState<Square | null>(null)

  const {position, seat, canPlay, onDrop, disableOverlays} = props
  const boardWidth = props.width || defaultBoardWidth

  const turn = position.turn
  const [kingRank, kingFile] = toRF(position.findKing())
  const {minerals, mineralReserves} = position

  const availablePlantingSquares: Square[] = []

  if (canPlay) {
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        if (Math.abs(rank - kingRank) <= 1 && Math.abs(file - kingFile) <= 1 && !position.get(toSquare(rank, file)) &&
          !position.plants.find(plant => plant.rank === rank && plant.file === file)) {
          availablePlantingSquares.push(toSquare(rank, file))
        }
      }
    }
  }

  function CustomSquare(props: CustomSquareProps) {
    const {
      children,
      square,
      style
    } = props
    const [rank, file] = toRF(square);
    const plant = position.plants.find(plant => plant.rank === rank && plant.file === file)
    const mineral = minerals[rank][file]
    const piece = position.get(square);
    const canPlantHere = availablePlantingSquares.includes(square)

    return <div style={{...style, position: "relative", cursor: canPlantHere ? "pointer" : "default"}}>
      {children}
      {!disableOverlays && <>
        {(mineral && <MineralOverlay mineral={mineral} hover={hoveredSquare === square}/>) || ''}
        {(plant && <PlantOverlay plant={plant} conceal={seat !== plant.color}/>) || ''}
        {(piece && piece.type === 'k' &&
            <KingReservesOverlay color={piece.color} reserve={mineralReserves[playerIndex(piece.color)]}/>) || ''}
        {(((canPlantHere && hoveredSquare === square) || square === plantingSquare) && <PlantDot/>) || ''}
      </>}
    </div>
  }

  function onPieceDrop(source: string, target: string): boolean {
    if (!onDrop) return false
    if (!onDrop(source as Square, target as Square)) return false
    setPlantingSquare(null)
    return true
  }

  function onPlantClick(piece: keyof typeof plantCosts) {
    if (!plantingSquare) return
    const [rank, file] = toRF(plantingSquare);
    const newPlant = {color: turn, piece, rank, file, progress: 0}
    props.onPlantAdded?.(newPlant)
    setPlantingSquare(null)
  }

  function onSquareClick(square: Square) {
    if (!availablePlantingSquares.includes(square)) return
    if (plantingSquare === square) {
      setPlantingSquare(null)
      return
    }
    setPlantingSquare(square)
  }

  function MyPieceButton(props: { piece: "P" | "N" | "B" | "R" | "Q" }) {
    const color = turn === 'w' ? 'white' : 'black'
    return <div style={{display: "inline-block", margin: 5}}>
      <Button variant={'contained'} value={color[0] + props.piece} style={{width: 50, height: 50}}
              onClick={() => onPlantClick(props.piece.toLowerCase() as keyof typeof plantCosts)}>
        <Piece piece={props.piece} color={color} width={40}/>
      </Button>
    </div>
  }

  // console.log("rendering chessboard with fen", farmChess.fen())
  const canPlantPawn = plantingSquare && toRF(plantingSquare)[0] !== 0 && toRF(plantingSquare)[0] !== 7

  return <div style={{display: 'flex', flexDirection: 'row'}}>
    <div style={{maxWidth: boardWidth}}>

      <Chessboard id='custom'
                  customSquare={CustomSquare}
                  boardWidth={boardWidth}
                  position={position.fen()}
                  onPieceDrop={onPieceDrop}
                  onSquareClick={onSquareClick}
                  onMouseOverSquare={setHoveredSquare}
                  onMouseOutSquare={() => setHoveredSquare(null)}
                  onPromotionCheck={() => false}
                  arePiecesDraggable={canPlay}
                  isDraggablePiece={({piece}) => piece[0] === turn}
                  boardOrientation={props.seat === "b" ? "black" : "white"}
      />
    </div>

    {plantingSquare && <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
      {canPlantPawn && <MyPieceButton piece="P"/>}
        <MyPieceButton piece="N"/>
        <MyPieceButton piece="B"/>
        <MyPieceButton piece="R"/>
        <MyPieceButton piece="Q"/>
    </div>}
    <div style={{flex: 1}}/>
  </div>
}
