import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory();

export const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=d3e389ae-4a6e-47bb-9d56-8c2d884f2ebf;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
    extensions: [
      reactPlugin,
    ],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: {history: browserHistory},
    }
  }
});
appInsights.loadAppInsights();