import {FarmChessPosition} from '../core/farmChessPosition'
import { Chip} from '@mui/material'
import {Color} from '../core/types'
import {useEffect, useRef} from 'react'

export interface GameState {
  action: string;
  position: FarmChessPosition
}

export interface GameHistoryProps {
  history: GameState[]
  selectedIndex: number
  onIndexSelected: (index: number) => void
}


interface HistoryObject {
  color: Color | null
  action: string
  position: FarmChessPosition
}

export function GameHistory(props: GameHistoryProps) {
  const {history, selectedIndex, onIndexSelected} = props
  const historyObjects: HistoryObject[] = history.map((state, index) => {
    const color = index === 0 ? null : index % 2 === 0 ? 'b' : 'w'
    return {color, action: state.action, position: state.position}
  })

  const currentChipRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (currentChipRef.current) {
      currentChipRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [selectedIndex])

  return <div style={{padding: '10px', backgroundColor: '#352'}}>
    <div style={{width: '500px', maxHeight: '500px', overflowY: 'scroll'}}>
      {historyObjects.map((obj, index) => {
          let chipColor = 'grey'
          if (index === selectedIndex) chipColor = 'blue'
          else chipColor = obj.color === 'w' ? 'white' : 'black'

          return <Chip key={index}
                       ref={index === selectedIndex ? currentChipRef : null}
                       label={obj.action}
                       onClick={() => onIndexSelected(index)}
                       style={{
                         margin: '2px',
                         backgroundColor: chipColor,
                         color: chipColor === 'white' ? 'black' : 'white',
                       }}/>
        }
      )}
    </div>
  </div>

}

