import React, {useEffect, useState} from 'react'
import FarmChessBoard, {defaultBoardWidth} from './board/FarmChessBoard'
import {Button, Checkbox} from '@mui/material'
import {useChess} from '../hooks/useChess'
import {GameHistory} from './GameHistory'
import {rand} from '../utils'
import {LinkButton} from './LinkButton'
import {setAndClearTimeout} from '../hooks/useTimeout'

export function LocalBoard() {
  const [seat, setSeat] = useState<'w' | 'b'>('w')
  const [whiteBot, setWhiteBot] = useState(false)
  const [blackBot, setBlackBot] = useState(true)

  const {boardStuff, historyStuff, moveRandom, plantRandom} = useChess()
  const {isLive} = historyStuff
  const {position} = boardStuff

  useEffect(() => {
    if (!(position.turn === 'w' && whiteBot || position.turn === 'b' && blackBot)) return;
    return setAndClearTimeout(() => {
      if (!isLive) return;
      if (rand(0.2) && plantRandom()) return
      moveRandom()
    }, 500)

  }, [position, whiteBot, blackBot, moveRandom, plantRandom, isLive]);

  function flipBoard() {
    setSeat(seat === 'w' ? 'b' : 'w')
  }

  return <div style={{display: 'flex', flexDirection: 'row'}}>
    <div style={{width: defaultBoardWidth + 100}}>
      <FarmChessBoard {...boardStuff} seat={seat} canPlay={isLive}/>
    </div>
    <div style={{flex: 1, marginLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <LinkButton to={'/'}>Back to lobby</LinkButton>
      <Button variant={'contained'} onClick={flipBoard}>Flip board</Button>
      <GameHistory {...historyStuff} />
      <div>
        <Checkbox checked={whiteBot} onChange={(e) => setWhiteBot(e.target.checked)}/> White moves randomly
        <Checkbox checked={blackBot} onChange={(e) => setBlackBot(e.target.checked)}/> Black moves randomly
      </div>
    </div>

  </div>
}
