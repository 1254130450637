import {plantCosts} from "../../utils";
import {Color} from "chess.js";
import Piece from "@chessire/pieces";

export function MyPiece(props: { piece: keyof typeof plantCosts, color: Color, width: number }) {
  let p: "P" | "N" | "B" | "R" | "Q" = "P"
  if (props.piece === "n") p = "N"
  else if (props.piece === "b") p = "B"
  else if (props.piece === "r") p = "R"
  else if (props.piece === "q") p = "Q"

  const c: "white" | "black" = props.color === "w" ? "white" : "black"
  return <Piece piece={p} color={c} width={props.width}/>

}