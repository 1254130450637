import {useCallback, useContext, useEffect, useState} from 'react'
import {ProfileContext} from '../ProfileProvider'
import {saveGame} from '../repos/gameRepo'
import {get, onValue, ref} from 'firebase/database'
import {db} from '../firebaseConfig'
import {FarmChessPosition, FarmChessPositionDocument} from '../core/farmChessPosition'
import {useChess} from './useChess'


export interface GameDocument {
  whiteUserId: string
  blackUserId: string
  result?: 'w' | 'b' | 'draw'
  initialMinerals: number[][]
  latestPosition: FarmChessPositionDocument
  history?: string[]
}

export function useChessByGameId(gameId: string) {
  const {currentProfile: profile} = useContext(ProfileContext)

  const [gameDoc, setGameDoc] = useState<GameDocument | null>(null)
  const [whiteUsername, setWhiteUsername] = useState<string | null>(null)
  const [blackUsername, setBlackUsername] = useState<string | null>(null)
  const [seat, setSeat] = useState<'w' | 'b' | null>(null)
  const [frozen, setFrozen] = useState(false)

  const {boardStuff, historyStuff} = useChess()
  const {position} = boardStuff
  const {isLive, onIndexSelected, history, setHistory} = historyStuff

  useEffect(() => {
    if (!gameDoc) return
    if (!isLive) return
    if (position && gameDoc.latestPosition &&
      JSON.stringify(position.toDocument()) ===
      JSON.stringify(new FarmChessPosition(gameDoc.latestPosition).toDocument())) {
      return
    }
    const newDoc: GameDocument = {
      ...gameDoc,
      history: history.slice(1).map(h => h.action),
      initialMinerals: history[0].position.minerals,
      latestPosition: position.toDocument()
    }
    console.log('Saving')
    saveGame(gameId, newDoc)
  }, [gameDoc, position, history, gameId])

  const updateFromSnapshot = useCallback((doc: GameDocument) => {
    if (!doc) return
    console.log('Received new doc:', doc)
    setGameDoc(doc)
    if (doc.whiteUserId) {
      get(ref(db, `users/${doc.whiteUserId}/username`)).then((snapshot) => {
        setWhiteUsername(snapshot.val())
      })
    }
    if (doc.blackUserId) {
      get(ref(db, `users/${doc.blackUserId}/username`)).then((snapshot) => {
        setBlackUsername(snapshot.val())
      })
    }
    if (doc.whiteUserId === profile.userId) {
      setSeat('w')
    } else if (doc.blackUserId === profile.userId) {
      setSeat('b')
    } else {
      setSeat(null)
    }
    let position = new FarmChessPosition()
    position.minerals = doc.initialMinerals
    const newHistory = [{action: '(start)', position}]
    if (doc.history) {
      for (const action of doc.history || []) {
        position = position.playAction(action)
        newHistory.push({action, position})
      }
    }
    setHistory(newHistory)
    if (isLive) onIndexSelected(newHistory.length - 1)
  }, [profile])

  useEffect(() => {
    get(ref(db, `archivedGames/${gameId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        updateFromSnapshot(snapshot.val())
        setFrozen(true)
      }
    })

    return onValue(ref(db, `games/${gameId}`), (snapshot) => {
      if (snapshot.exists()) {
        updateFromSnapshot(snapshot.val() as GameDocument)
      }
    })
  }, [gameId, updateFromSnapshot])

  return {
    boardStuff: {
      ...boardStuff,
      seat,
      frozen
    },
    historyStuff: {
      ...historyStuff,
      history: history.map((h, i) => {
        const seatOfAction = i % 2 === 0 ? 'b' : 'w'
        if (seat === seatOfAction) return h
        const { action } = h
        const toks = action.split('/')
        const newToks = toks.map(tok => {
          if (tok.startsWith('+')) return '+' + tok.slice(2)
          return tok
        })
        return {
          ...h,
          action: newToks.join('/')
        }
      })
    },
    gameDoc, whiteUsername, blackUsername
  }
}
