// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA8E9Yy1zD_svM19njVKyERX3MvlOXiezs",
    authDomain: "farm-chess-5c48c.firebaseapp.com",
    projectId: "farm-chess-5c48c",
    storageBucket: "farm-chess-5c48c.appspot.com",
    messagingSenderId: "544375636758",
    appId: "1:544375636758:web:01265c94d0af5e9d1a0637"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getDatabase(app)

