import './App.css'
import React from 'react'
import {ProfileProvider} from './ProfileProvider'
import {Container} from '@mui/material'
import {MyAppBar} from './components/MyAppBar'
import {Outlet} from 'react-router-dom'

function App() {

    return <div style={{
      backgroundColor: '#446',
      color: 'white'
    }}>
      <ProfileProvider>
        <MyAppBar />

        <Container sx={{ bgcolor: '#446'}}>
          <Outlet />
        </Container>

      </ProfileProvider>
    </div>
}

export default App;
