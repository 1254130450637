import React, {ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import {Button, List, Paper} from "@mui/material";

interface DevEntry {
  title: string;
  contents: ReactNode
  finishedDate?: string
}

const items: DevEntry[] = [
  {
    title: 'History navigator',
    contents: <>
      <p>Allow users to navigate back and forth in the game history by clicking or arrows</p>
    </>,
    finishedDate: '2024-04-02'
  },
  {
    title: 'Discord',
    contents: <>
      <p>Build a Discord server to rapidly collect feedback (and gab with players!)</p>
    </>,
    finishedDate: '2024-04-07'
  },
  {
    title: 'Create Tutorial',
    contents: <>
      <p>The rules explanation is not 100% clear. A playable guided tutorial that explains the mechanics would
        be better</p>
    </>
  },
  {
    title: 'Handicap game',
    contents: <>
      <p>Allow players to configure a game in which the weaker player is spotted some number of
        minerals to start the game</p>
    </>
  },
  {
    title: 'Animations',
    contents: <>
      <p>Animate mineral collection and plant growing, to promote clarity. (Allow animations to be toggled on/off)</p>
    </>
  },

]

export function DevBlog() {
  const navigate = useNavigate()

  return <div>
    <h1>Dev Blog / TODO list</h1>
    <List sx={{bgcolor: '#567'}}>
      {items.map(item =>
        <Paper sx={{padding: '10px', margin: '10px'}}>
          <div>
            <h3>{item.title}{item.finishedDate && <> (✅ {item.finishedDate})</>}</h3>
          </div>
          <div>

            {item.contents}
          </div>
        </Paper>
      )}
    </List>
    <Button onClick={() => navigate('/')}>Back</Button>
  </div>
}
