import {get, ref, remove, set} from 'firebase/database'
import {db} from '../firebaseConfig'
import {FarmChessPosition} from '../core/farmChessPosition'
import {v4 as uuidv4} from 'uuid'
import {GameDocument} from '../hooks/useChessByGameId'

export async function createGame(whitePlayerId: string, blackPlayerId: string): Promise<string> {
  const gameId = uuidv4()
  const position = new FarmChessPosition()
  const newDoc: GameDocument = {
    whiteUserId: whitePlayerId,
    blackUserId: blackPlayerId,
    initialMinerals: new FarmChessPosition().minerals,
    latestPosition: position.toDocument(),
    history: [],
  }
  await set(ref(db, `games/${gameId}`), newDoc)
  return gameId
}

export async function loadGame(gameId: string): Promise<GameDocument> {
  const snapshot = await get(ref(db, `games/${gameId}`))
  return snapshot.val()
}

export async function saveGame(gameId: string, doc: GameDocument) {
  await set(ref(db, `games/${gameId}`), doc)
}

export async function saveArchive(gameId: string, doc: GameDocument) {
  await set(ref(db, `archivedGames/${gameId}`), doc)
}

export async function deleteGame(gameId: string) {
  await remove(ref(db, `games/${gameId}`))
}

export async function findMyGames(playerId?: string): Promise<string[]> {
  const games = await get(ref(db, `games`))
  const myGames: string[] = []
  games.forEach(game => {
    if (!playerId || game.val().whiteUserId === playerId || game.val().blackUserId === playerId) {
      myGames.push(game.key)
    }
  })
  return myGames
}

export async function listAllGames(): Promise<string[]> {
  const games = await get(ref(db, `games`))
  const allGames: string[] = []
  games.forEach(game => {
    allGames.push(game.key)
  })
  return allGames
}
