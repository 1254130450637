import {Plant} from "../../core/types";
import {MyPiece} from "./MyPiece";
import {plantCosts} from "../../utils";

export function PlantOverlay(props: { plant: Plant, conceal: boolean }) {
  function Block(props: { borderColor: string, fillColor?: string }) {
    return <div style={{
      width: 10,
      height: 5,
      borderRadius: 3,
      backgroundColor: props.fillColor,
      border: "1px solid " + props.borderColor,
      boxSizing: "border-box",
      marginTop: 1,
    }}/>

  }

  const {plant} = props
  const color = plant.color === "w" ? "#fff" : "#000"
  return <div
    style={{
      position: "absolute",
      right: 2,
      bottom: 2,
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
      justifyContent: "center",
      // height: 20,
      width: 20,
      borderRadius: 6,
      paddingTop: 2,
      paddingBottom: 2,
      // paddingLeft: -2,
      // paddingRight: -2,
      backgroundColor: `rgba(70, 110, 70)`,
      color: plant.color === "w" ? "#fff" : "#000",
      fontSize: 12,
      zIndex: 100,
    }}>
    {!props.conceal ?
      <MyPiece piece={plant.piece} color={plant.color} width={20}/> :
      <span style={{fontWeight: 'bolder'}}>?</span>
    }
    {Array(plantCosts[plant.piece]).fill(null).map((_, i) =>
      (i < plant.progress || !props.conceal) &&
        <Block borderColor={color} fillColor={i < plant.progress ? '#3f3' : undefined} key={i}/>)}
  </div>
}
