import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../../ProfileProvider";
import {JoinDocument, loadAllJoinCodes, monitorJoinCodes} from "../../repos/joincodeRepo";
import {Button} from "@mui/material";

export function OpenGamesList(props: { onClick: (joinCode: string) => void, onAbort: (joinCode: string) => void }) {
  const {onClick, onAbort} = props
  const {currentProfile: profile} = useContext(ProfileContext)

  useEffect(() => {
    return monitorJoinCodes(() => loadAllJoinCodes().then(setOpenGames))
  }, [])

  const [openGames, setOpenGames] = useState<{ joinCode: string, doc: JoinDocument }[]>([])

  return <div>
    <ul>
      {openGames.map(({joinCode, doc}) => <li key={joinCode}>
        <OpenGame joinCode={joinCode} doc={doc} isMe={doc.inviterId === profile.userId}/>
      </li>)}
    </ul>
  </div>

  function OpenGame({joinCode, doc, isMe}: { joinCode: string, doc: JoinDocument, isMe: boolean }) {
    return <div>
      {isMe ? <>
        <Button variant={'contained'} color={'error'}
                onClick={() => onAbort(joinCode)}>Abort</Button> vs. {doc.inviterUsername}
      </> : <>
        <Button variant={'contained'} onClick={() => onClick(joinCode)}>Join</Button> vs. {doc.inviterUsername}
      </>}
    </div>
  }
}