import {useChessByGameId} from "../hooks/useChessByGameId";
import {Chip} from "@mui/material";
import FarmChessBoard from "./board/FarmChessBoard";
import React from "react";

export function MiniGameComponent(props: { gameId: string, onClick: () => void }) {
  const {boardStuff, whiteUsername, blackUsername} = useChessByGameId(props.gameId)

  if (!whiteUsername || !blackUsername) return <div>Loading...</div>

  const whitePlayerArea = <>
    <Chip label={whiteUsername} color={'info'}/>
    { boardStuff.seat === 'w' && boardStuff.position.turn === 'w' && <>My move!</> }
  </>
  const blackPlayerArea = <>
    <Chip label={blackUsername} color={'info'}/>
    { boardStuff.seat === 'b' && boardStuff.position.turn === 'b' && <>My move!</> }
  </>

  const { seat } = boardStuff

  return <div onClick={() => props.onClick()} style={{cursor: "pointer", margin: '10px'}}>
    {seat === 'b' ? whitePlayerArea : blackPlayerArea}
    <FarmChessBoard {...boardStuff} canPlay={false} width={200} disableOverlays/>
    {seat === 'b' ? blackPlayerArea : whitePlayerArea}
  </div>

}
