import React, {useEffect} from 'react'
import {Button, Chip} from '@mui/material'
import FarmChessBoard, {defaultBoardWidth} from './board/FarmChessBoard'
import {deleteGame, saveArchive} from '../repos/gameRepo'
import {LinkButton} from './LinkButton'
import {GameDocument, useChessByGameId} from '../hooks/useChessByGameId'
import {GameHistory} from './GameHistory'
import {useNavigate} from 'react-router-dom'
import {setAndClearTimeout} from '../hooks/useTimeout'

export function GameComponent(props: { gameId: string, adminMode: boolean }) {
  const {gameId, adminMode} = props
  const {boardStuff, historyStuff, gameDoc, whiteUsername, blackUsername} = useChessByGameId(gameId)
  const {position, frozen, seat} = boardStuff
  const navigate = useNavigate()

  const turn = position.turn

  useEffect(() => {
    if (seat === turn && !frozen && position.buildMoves().next().done) {
      return setAndClearTimeout(() => {
        resign(true)
      }, 1000)
    }
  }, [seat, turn, frozen, position])


  if (!gameDoc) return <div>Loading...</div>
  if (!whiteUsername || !blackUsername) return <div>Loading...</div>

  async function resign(force?: boolean) {
    if (seat === null) return
    if (gameDoc === null) return
    if (!force && !confirm('Are you sure you want to resign?')) return

    const newDoc: GameDocument = {
      ...gameDoc,
      result: seat === 'w' ? 'b' : 'w'
    }

    await saveArchive(gameId, newDoc)
    await deleteGame(gameId)
    if (force) alert('Game over!')
  }

  async function archive() {
    if (!gameDoc) return
    await saveArchive(gameId, gameDoc)
    await deleteGame(gameId)
    navigate('/')
  }

  function PlayerArea(props: { username: string, yourMove: boolean, isYou: boolean }) {
    const {username, yourMove} = props

    return <div style={{display: 'flex', flexDirection: 'row', height: '40px', alignItems: 'center'}}>
      <div style={{flex: 1, fontWeight: 'bold'}}>
        <Chip label={username} color={'info'}/>{yourMove && !frozen ? ' (Your move)' : ''}
      </div>
      {props.isYou && !frozen && <Button variant={'contained'} color={'error'} onClick={() => resign()}>Resign</Button>}
    </div>
  }


  const whitePlayerArea = <PlayerArea username={whiteUsername} yourMove={turn === 'w'} isYou={seat === 'w'}/>
  const blackPlayerArea = <PlayerArea username={blackUsername} yourMove={turn === 'b'} isYou={seat === 'b'}/>

  let content = <div>Loading...</div>
  if (position) content = <>
    {seat === 'b' ? whitePlayerArea : blackPlayerArea}
    <FarmChessBoard {...boardStuff} canPlay={seat === position.turn && !frozen}/>
    {seat === 'b' ? blackPlayerArea : whitePlayerArea}
  </>

  function onKeyDown(ev: React.KeyboardEvent<HTMLDivElement>) {
    if (ev.key === 'ArrowLeft' || ev.key === 'ArrowUp') historyStuff.back()
    if (ev.key === 'ArrowRight' || ev.key === 'ArrowDown') historyStuff.forward()
  }

  return <div style={{display: 'flex', flexDirection: 'row'}} className={'dontShowFocus'} tabIndex={-1} onKeyDown={(e) => onKeyDown(e)} >
    <div style={{width: defaultBoardWidth + 100}}>
      {content}
    </div>
    <div style={{flex: 1, marginLeft: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <LinkButton to={'/'}>Back to lobby</LinkButton>
      <GameHistory {...historyStuff} />
      {adminMode && <Button variant={'contained'} color={'error'} onClick={() => archive()}>Archive game</Button>}
    </div>

  </div>
}
