import {createBrowserRouter, useParams, useSearchParams} from 'react-router-dom'
import App from './App'
import {GameComponent} from './components/GameComponent'
import {LocalBoard} from "./components/LocalBoard";
import {MenuScreen} from "./components/menu/MenuScreen";
import {DevBlog} from "./components/menu/DevBlog";

function RoutedGameComponent() {
  const {gameId} = useParams()
  const [search] = useSearchParams()
  const adminMode = search.get('admin') === 'true'
  if (!gameId) throw "No gameId"
  return <GameComponent gameId={gameId} adminMode={adminMode}/>
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children: [
      {
        path: 'game/:gameId',
        element: <RoutedGameComponent/>
      },
      {
        path: '/local',
        element: <LocalBoard/>
      },
      {
        path: '/dev',
        element: <DevBlog/>
      },
      {
        path: '/',
        element: <MenuScreen/>
      }
    ]
  }
])
