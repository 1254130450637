import {useNavigate} from "react-router-dom";
import {MiniGameComponent} from "./MiniGameComponent";

export function GameBrowser({gameIds}: { gameIds: string[] }) {

  const navigate = useNavigate()

  return <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
    {gameIds.map(gameId => <MiniGameComponent gameId={gameId} key={gameId}
                                              onClick={() => navigate(`/game/${gameId}`)}/>)}
  </div>
}
