import {Chess, Color, Square} from "chess.js";

export function toRF(square: string) {
    return [8 - parseInt(square[1]), "abcdefgh".indexOf(square[0])];
}
export function toSquare(rank: number, file: number) {
    return ("abcdefgh"[file] + (8 - rank)) as Square;
}

export function enemy(color: Color) { return color === 'w' ? 'b' : 'w' }
export function playerIndex(color: Color) { return color === 'w' ? 0 : 1 }

export function sample<T>(arr: T[]): T {
    return arr[Math.floor(Math.random() * arr.length)];
}

export const plantCosts = {
    p: 2,
    n: 3,
    b: 3,
    r: 5,
    q: 9
}

export function findKing(chess: Chess, color: Color) {
    for (let rank = 0; rank < 8; rank++) {
        for (let file = 0; file < 8; file++) {
            const piece = chess.get(toSquare(rank, file));
            if (piece && piece.type === 'k' && piece.color === color) {
                return [rank, file];
            }
        }
    }
    return [-1, -1];
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function rand(prob: number) {
    return Math.random() < prob;
}