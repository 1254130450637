export function PlantDot() {
    return <div
        style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
    >
        <div style={{width: 20, height: 20, backgroundColor: "#383", borderRadius: "50%"}}/>
    </div>
}