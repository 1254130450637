import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {GoogleOAuthProvider} from '@react-oauth/google'
import {router} from './router'
import {RouterProvider} from 'react-router-dom'
import {reactPlugin} from "./appInsights";
import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="912249991884-fkoehcg6qahsfkt3vmrvogk265fmhivc.apps.googleusercontent.com">
      <AppInsightsContext.Provider value={reactPlugin}>
        <RouterProvider router={router}/>
      </AppInsightsContext.Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
