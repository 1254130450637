export function KingReservesOverlay(props: { color: "w" | "b", reserve: number }) {
    const {color, reserve} = props

    return <div
        style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 16,
        }}>
        <div style={{
            pointerEvents: "none",
            zIndex: 100,
            width: 20,
            height: 20,
            marginTop: "20px",
            backgroundColor: "#88a",
            borderRadius: "50%",
            textAlign: "center",
            fontWeight: 'bold',
            color: color === 'w' ? 'black' : 'white',
        }}>
            {reserve}
        </div>
    </div>
}