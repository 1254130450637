import {useContext, useEffect, useState} from 'react'
import {findMyGames, listAllGames} from '../../repos/gameRepo'
import {useNavigate} from 'react-router-dom'
import {ProfileContext} from '../../ProfileProvider'
import { deleteJoinCode, joinGame} from "../../repos/joincodeRepo";
import {OpenGamesList} from "./OpenGamesList";
import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {GameBrowser} from "../GameBrowser";

export function MenuScreen() {
  const navigate = useNavigate()
  const {currentProfile: profile} = useContext(ProfileContext)
  const appInsights = useAppInsightsContext()

  const [myGameIds, setMyGameIds] = useState<string[] | undefined>()
  const [activeGameIds, setActiveGameIds] = useState<string[] | undefined>()

  useEffect(() => {
    if (profile) {
      findMyGames(profile.userId).then(setMyGameIds)
    }
    listAllGames().then(setActiveGameIds)
  }, [profile])

  async function joinAGame(joinCode: string) {
    appInsights.trackEvent({name: 'joined-game', properties: {joinCode}})

    const gameId = await joinGame(joinCode, profile.userId)
    if (gameId) {
      navigate(`/game/${gameId}`)
    } else {
      alert('Game no longer available')
    }
  }

  async function abortAGame(joinCode: string) {
    appInsights.trackEvent({name: 'aborted-game', properties: {joinCode}})
    return deleteJoinCode(joinCode)
  }

  return <div>
    <div>
      <h2>My Games</h2>
      { myGameIds ?
        <GameBrowser gameIds={myGameIds}/> :
        <p>Loading...</p>
      }
      {/*<GamesList onClick={loadGame} gameLoader={() => findMyGames(profile.userId)}/>*/}

      <h2>Open Games</h2>
      <OpenGamesList onClick={joinAGame} onAbort={abortAGame}/>

      <h2>Active Games</h2>
      { activeGameIds ?
        <GameBrowser gameIds={activeGameIds}/> :
        <p>Loading...</p>
      }

    </div>
  </div>
}
