import {sample} from "./utils"

const adjectives = [
  'kingside',
  'queenside',
  'lightsquared',
  'darksquared',
  'open',
  'closed',
  'isolated',
  'doubled',
  'backward',
  'connected',
  'hanging',
]

const nouns = [
  'pawn',
  'knight',
  'bishop',
  'rook',
  'queen',
  'king',
  'file',
  'rank',
  'diagonal',
  'square',
  'center',
  'piece',
]

const verbs = [
  'grabber',
  'blocker',
  'mover',
  'pusher',
  'attacker',
  'defender',
  'exchanger',
  'sacrificer',
  'capturer',
  'promoter',
  'checker',
  'forker',
  'skewerer',
  'pinner',
  'stormer',
]

export function makeUsername() {
  return `${sample(adjectives)}-${sample(nouns)}-${sample(verbs)}`
}