import {get, onValue, ref, remove, set} from "firebase/database";
import {db} from "../firebaseConfig";
import {createGame} from "./gameRepo";

export interface JoinDocument {
  inviterId: string
  inviterUsername: string
  // Other game parameters
}

async function findNewJoinCode(): Promise<string> {
  let joinCode = ''
  do {
    joinCode = ''
    for (let i = 0; i < 4; i++) {
      joinCode += String.fromCharCode(65 + Math.floor(Math.random() * 26))
    }
    const snapshot = await get(ref(db, `joinCodes/${joinCode}`))
    if (snapshot.exists()) joinCode = ''
  } while (!joinCode)

  return joinCode
}

export async function createJoinCode(userId: string, username: string): Promise<string> {
  const joinCode = await findNewJoinCode()
  const newDoc: JoinDocument = {
    inviterId: userId,
    inviterUsername: username,
  }
  await set(ref(db, `joinCodes/${joinCode}`), newDoc)
  return joinCode
}

// Returns gameId of created game (if any)
export async function joinGame(joinCode: string, userId: string): Promise<string | null> {
  const joinDoc = await get(ref(db, `joinCodes/${joinCode}`))
  if (!joinDoc.exists()) return null
  const {inviterId} = joinDoc.val() as JoinDocument
  if (inviterId === userId) return null

  // TODO: Check who's stronger, etc.
  const whiteId = Math.random() < 0.5 ? userId : inviterId
  const blackId = whiteId === userId ? inviterId : userId

  await remove(ref(db, `joinCodes/${joinCode}`))
  return await createGame(whiteId, blackId)
}

export async function loadAllJoinCodes(): Promise<{ joinCode: string, doc: JoinDocument }[]> {
  const snapshot = await get(ref(db, `joinCodes`))
  const joinCodes: { joinCode: string, doc: JoinDocument }[] = []
  snapshot.forEach((joinCode) => {
    joinCodes.push({joinCode: joinCode.key, doc: joinCode.val()})
  })
  return joinCodes
}

export function monitorJoinCodes(callback: () => void) {
  const joinCodesRef = ref(db, 'joinCodes')
  return onValue(joinCodesRef, () => callback())
}

export async function deleteJoinCode(joinCode: string) {
  return remove(ref(db, `joinCodes/${joinCode}`))
}