export function MineralOverlay(props: { mineral: number, hover: boolean }) {
    function Dot(props: { borderColor: string, fillColor?: string }) {
        return <div style={{
            width: 6,
            height: 6,
            borderRadius: 3,
            backgroundColor: props.fillColor,
            // borderColor: props.borderColor,
            border: "2px solid " + props.borderColor,
            boxSizing: "border-box",
            margin: 1,
        }}/>
    }

    return <>
        {props.hover && <div style={{
            position: "absolute",
            left: 10,
            bottom: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 20,
            width: 16,
            borderTopRightRadius: 10,
            backgroundColor: `rgba(0, 0, 0, 0.5)`,
            zIndex: 100,
            color: "#fff",
            fontSize: 14,
        }}>
            {props.mineral}
        </div>}
        <div style={{
            position: "absolute",
            left: 2,
            bottom: 2,
        }}>
            {Array(Math.min(11, props.mineral)).fill(null).map((mineral, i) => <Dot fillColor={"#457"}
                                                                                    borderColor={"#457"} key={i}/>)}
        </div>
    </>
}