import {Color, Move, Piece, PieceSymbol, Plant, Square} from "./types";
import {enemy, plantCosts, playerIndex, sample, toRF, toSquare} from "../utils";


interface MoveValidResult {
  valid: boolean
  enPassant?: Square
  capturedEnPassant?: Square
}

export interface FarmChessPositionDocument {
  fen: string
  minerals: number[][]
  mineralReserves: number[]
  plants?: Plant[]
}

export class FarmChessPosition {
  turn: Color = 'w'
  private board: (Piece | null)[][] = []
  private enPassant: Square | null = null
  minerals: number[][]
  mineralReserves: number[] = [0, 0]
  plants: Plant[] = []

  isInCheck(color: Color): boolean {
    const kingSquare = this.findKing(color)
    if (!kingSquare) return false

    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const piece = this.board[rank][file]
        if (!piece) continue
        if (piece.color === color) continue

        if (piece.type === 'p' && this.pawnMove(toSquare(rank, file), kingSquare).valid) return true
        if (piece.type === 'n' && this.knightMove(toSquare(rank, file), kingSquare)) return true
        if (piece.type === 'b' && this.bishopMove(toSquare(rank, file), kingSquare)) return true
        if (piece.type === 'r' && this.rookMove(toSquare(rank, file), kingSquare)) return true
        if (piece.type === 'q' && this.queenMove(toSquare(rank, file), kingSquare)) return true
        if (piece.type === 'k' && this.kingMove(toSquare(rank, file), kingSquare)) return true
      }
    }
    return false
  }

  private clear() {
    this.board = []
    for (let i = 0; i < 8; i++) {
      this.board[i] = []
      for (let j = 0; j < 8; j++) {
        this.board[i][j] = null
      }
    }
  }

  get(square: Square): Piece | null {
    const [rank, file] = toRF(square)
    return this.board[rank][file]
  }

  private buildMinerals() {
    const newMinerals = Array(8).fill(null).map(() => Array(8).fill(0));

    function totalMinerals() {
      return newMinerals.reduce((acc, row) => acc + row.reduce((acc, mineral) => acc + mineral, 0), 0);
    }

    while (totalMinerals() < 60) {
      const rank = Math.floor(Math.random() * 8);
      const file = Math.floor(Math.random() * 8);
      const amount = Math.floor(Math.random() * 4) + 2
      newMinerals[rank][file] += amount;
      newMinerals[7 - rank][7 - file] += amount;
    }

    const candidates: Square[] = []
    // A pawn square must have minerals, for the first-mover advantage
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        if (this.get(toSquare(rank, file))?.type === 'p') {
          candidates.push(toSquare(rank, file))
        }
      }
    }
    const [pawnRank, pawnFile] = toRF(sample(candidates))
    newMinerals[pawnRank][pawnFile] += 2
    newMinerals[7 - pawnRank][7 - pawnFile] += 2
    return newMinerals
  }

  constructor(doc?: FarmChessPositionDocument) {
    this.clear()

    if (doc) {
      this.load(doc.fen)
      this.minerals = doc.minerals
      this.mineralReserves = doc.mineralReserves
      this.plants = doc.plants || []
      // console.log("loaded from document: ", doc)
      return
    }

    this.board[0][2] = {type: 'b', color: 'b'}
    this.board[0][3] = {type: 'k', color: 'b'}
    this.board[0][4] = {type: 'n', color: 'b'}
    this.board[1][2] = {type: 'p', color: 'b'}
    this.board[1][3] = {type: 'p', color: 'b'}
    this.board[1][4] = {type: 'p', color: 'b'}

    this.board[7][5] = {type: 'b', color: 'w'}
    this.board[7][4] = {type: 'k', color: 'w'}
    this.board[7][3] = {type: 'n', color: 'w'}
    this.board[6][5] = {type: 'p', color: 'w'}
    this.board[6][4] = {type: 'p', color: 'w'}
    this.board[6][3] = {type: 'p', color: 'w'}

    this.minerals = this.buildMinerals()
  }

  private load(fen: string) {
    this.clear()
    const [boardTok, turnTok, , enPassantTok] = fen.split(' ')
    const rows = boardTok.split('/')
    for (let i = 0; i < 8; i++) {
      const row = rows[i]
      let file = 0
      for (let j = 0; j < row.length; j++) {
        const c = row[j]
        if (c >= '1' && c <= '8') {
          file += parseInt(c)
        } else {
          this.board[i][file] = {
            type: c.toLowerCase() as PieceSymbol,
            color: c === c.toLowerCase() ? 'b' : 'w'
          }
          file++
        }
      }
    }
    this.turn = turnTok as Color
    this.enPassant = enPassantTok === '-' ? null : enPassantTok as Square
  }

  fen(): string {
    let fen = ''
    for (let i = 0; i < 8; i++) {
      let empty = 0
      for (let j = 0; j < 8; j++) {
        const piece = this.board[i][j]
        if (piece) {
          if (empty) {
            fen += empty
            empty = 0
          }
          fen += piece.color === 'w' ? piece.type.toUpperCase() : piece.type
        } else {
          empty++
        }
      }
      if (empty) {
        fen += empty
      }
      if (i < 7) {
        fen += '/'
      }
    }
    fen += ' ' + this.turn + ' - ' + (this.enPassant || '-') + ' 1 1'
    return fen
  }

  toDocument(): FarmChessPositionDocument {
    return {
      fen: this.fen(),
      minerals: this.minerals.map(row => row.slice()),
      mineralReserves: this.mineralReserves.slice(),
      plants: this.plants.map(plant => ({...plant}))
    }
  }

  findKing(color?: "w" | "b"): Square {
    const findColor = color ?? this.turn
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const piece = this.get(toSquare(rank, file))
        if (piece?.type === "k" && piece.color === findColor) {
          return toSquare(rank, file)
        }
      }
    }
    throw new Error("King not found")
  }

  * buildMoves(): Generator<Move> {
    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const piece = this.get(toSquare(rank, file))
        if (!piece || piece.color !== this.turn) continue
        for (let r = 0; r < 8; r++) {
          for (let f = 0; f < 8; f++) {
            if (!this.isValidFarmChessMove(toSquare(rank, file), toSquare(r, f))) continue
            const move: Move = {
              color: this.turn,
              from: toSquare(rank, file),
              to: toSquare(r, f),
              piece: piece.type
            }
            yield move
          }
        }
      }
    }
  }

  isValidFarmChessMove(from: Square, to: Square): boolean {
    const newPosition = new FarmChessPosition(this.toDocument())
    const move = newPosition.moveInternal(from, to);
    if (!move) return false
    newPosition.updatePlants(move.color);  // Grow the plants before checking for check
    return !newPosition.isInCheck(move.color);
  }

  copy() {
    return new FarmChessPosition(this.toDocument())
  }

  move(from: Square, to: Square): FarmChessPosition {
    const newPosition = this.copy()
    const move = newPosition.moveInternal(from, to);
    if (!move) throw "never happens"

    // Trample plants
    const [targetRank, targetFile] = toRF(to);
    const plant = newPosition.plants.find(plant => plant.rank === targetRank && plant.file === targetFile)
    if (plant && plant.color !== move.color) {
      newPosition.minerals[targetRank][targetFile] += plant.progress
      newPosition.plants = newPosition.plants.filter(p => p.rank !== targetRank || p.file !== targetFile)
    }

    // Drop minerals for captured pieces
    const capture = move.captured
    if (capture) {
      const [capRank, capFile] = toRF(capture.square)
      newPosition.minerals[capRank][capFile] += plantCosts[capture.piece as keyof typeof plantCosts]
    }
    // if (this.notifier) {
    //   // console.log("notifying")
    //   this.notifier(false)
    // }
    newPosition.updatePlants(move.color);
    newPosition.updateMinerals(enemy(move.color))

    return newPosition
  }

  updateMinerals(toMove: Color) {
    // update minerals for enemy player
    const movedColor = enemy(toMove);
    const playerIndexToMove = playerIndex(toMove)
    const playerIndexMoved = playerIndex(movedColor)

    for (let rank = 0; rank < 8; rank++) {
      for (let file = 0; file < 8; file++) {
        const mineral = this.minerals[rank][file]
        const piece = this.get(toSquare(rank, file));
        if (piece && piece.color === toMove && piece.type === 'p') {
          if (mineral > 0) {
            this.mineralReserves[playerIndexToMove] += 1
            this.minerals[rank][file] -= 1
          }
          if ((rank === 7 && piece.color === 'b') || (rank === 0 && piece.color === 'w')) {
            for (let i = 0; i < 2; i++) {
              if (this.mineralReserves[playerIndexMoved] > 0) {
                this.mineralReserves[playerIndexMoved] -= 1;
                this.mineralReserves[playerIndexToMove] += 1;
              }
            }
          }
        }
      }
    }
  }

  updatePlants(plantPlayer: Color) {
    const playerIndexOfPlants = playerIndex(plantPlayer)
    const [kingRank, kingFile] = toRF(this.findKing(plantPlayer))
    // update plants
    const newPlants = this.plants.map(plant => {
      if (this.get(toSquare(plant.rank, plant.file))?.color === enemy(plant.color)) {
        return null;
      }
      if (plant.color !== plantPlayer) return plant;
      if (this.get(toSquare(plant.rank, plant.file))) return plant;

      let newPlant: Plant | null = {...plant};

      if (Math.abs(plant.rank - kingRank) <= 1 && Math.abs(plant.file - kingFile) <= 1 &&
        this.mineralReserves[playerIndexOfPlants] > 0 &&
        plant.progress + this.minerals[plant.rank][plant.file] < plantCosts[plant.piece]) {
        this.mineralReserves[playerIndexOfPlants] -= 1;
        newPlant.progress += 1;
      } else if (this.minerals[plant.rank][plant.file] > 0) {
        this.minerals[plant.rank][plant.file] -= 1;
        newPlant.progress += 1;
      }
      if (newPlant.progress === plantCosts[newPlant.piece]) {
        this.put({type: newPlant.piece, color: newPlant.color}, toSquare(newPlant.rank, newPlant.file))
        newPlant = null;
      }
      return newPlant
    }).filter(plant => plant !== null) as Plant[];
    this.plants = newPlants;
  }

  addPlant(plant: Plant): FarmChessPosition {
    const newPosition = this.copy()
    newPosition.plants.push({...plant})
    return newPosition
  }

  playAction(action: string): FarmChessPosition {
    // an action is like: "+Nb4/+Qc3/c2-c4"
    // which means:
    // - plant a knight on b4
    // - plant a queen on c3
    // - move c2 to c4

    // an action can be "unfinished", like "@Nb4/" (no move as yet)

    let newPosition = this.copy()

    const actions = action.split("/")
    for (const a of actions) {
      if (a[0] === "+") {
        const piece = a[1].toLowerCase() as keyof typeof plantCosts
        const [atRank, atFile] = toRF(a.slice(2))

        newPosition = newPosition.addPlant({
          color: this.turn,
          piece,
          rank: atRank,
          file: atFile,
          progress: 0
        })
      } else if (a.length === 5) {
        const from = a.slice(0, 2) as Square
        const to = a.slice(3) as Square
        newPosition = newPosition.move(from, to)
      }
    }

    return newPosition
  }

  private isValidNormalChessMove(from: Square, to: Square): MoveValidResult {
    const movingPiece = this.get(from)
    if (!movingPiece) return {valid: false}
    if (movingPiece.color !== this.turn) return {valid: false}
    if (from === to) return {valid: false}
    if (this.get(to)?.color === this.turn) return {valid: false}

    switch (movingPiece.type) {
      case 'p':
        return this.pawnMove(from, to)
      case 'n':
        return {valid: this.knightMove(from, to)}
      case 'b':
        return {valid: this.bishopMove(from, to)}
      case 'r':
        return {valid: this.rookMove(from, to)}
      case 'q':
        return {valid: this.queenMove(from, to)}
      case 'k':
        return {valid: this.kingMove(from, to)}
      default:
        return {valid: false}
    }
  }

  // returns null if move is invalid
  // Allows the move if it leaves the King in check (!)
  private moveInternal(from: Square, to: Square): Move | null {
    const movingPiece = this.get(from)
    if (!movingPiece) return null

    const result = this.isValidNormalChessMove(from, to)
    if (!result?.valid) return null

    const captured: PieceSymbol | null = result?.capturedEnPassant ? 'p' : this.get(to)?.type || null

    const move: Move = {
      color: this.turn,
      from,
      to,
      piece: movingPiece.type
    }
    if (captured) {
      move.captured = {piece: captured, square: to}
    }
    if (result?.enPassant) {
      this.enPassant = result?.enPassant
    } else {
      this.enPassant = null
    }
    const [toRank, toFile] = toRF(to)
    this.board[toRank][toFile] = movingPiece
    const [fromRank, fromFile] = toRF(from)
    this.board[fromRank][fromFile] = null
    if (result?.capturedEnPassant) {
      const [epRank, epFile] = toRF(result.capturedEnPassant)
      this.board[epRank][epFile] = null
    }

    this.turn = this.turn === 'w' ? 'b' : 'w'
    return move
  }

  private pawnMove(from: Square, to: Square): MoveValidResult {
    const [fromRank, fromFile] = toRF(from)
    const [toRank, toFile] = toRF(to)
    const direction = this.turn === 'w' ? -1 : 1

    if (fromFile === toFile) {
      if (this.get(to)) return {valid: false}
      if (toRank === fromRank + direction) return {valid: true}
      if (toRank === fromRank + 2 * direction && this.board[fromRank + direction][toFile] === null &&
        fromRank === (this.turn === 'w' ? 6 : 1)) {
        return {valid: true, enPassant: toSquare(fromRank + direction, fromFile)}
      }
    } else {
      if (toRank === fromRank + direction && Math.abs(toFile - fromFile) === 1) {
        if (this.get(to)) return {valid: true}
        if (to === this.enPassant) return {valid: true, capturedEnPassant: toSquare(toRank - direction, toFile)}
      }
    }
    return {valid: false}
  }

  put(piece: Piece, square: Square) {
    const [rank, file] = toRF(square)
    this.board[rank][file] = piece
  }

  private knightMove(from: Square, to: Square) {
    const [rank, file] = toRF(from)
    const [toRank, toFile] = toRF(to)
    const dr = Math.abs(toRank - rank)
    const df = Math.abs(toFile - file)
    return (dr === 2 && df === 1) || (dr === 1 && df === 2)
  }

  private bishopMove(from: Square, to: Square) {
    const [rank, file] = toRF(from)
    const [toRank, toFile] = toRF(to)
    if (Math.abs(toRank - rank) !== Math.abs(toFile - file)) return false
    const dr = toRank - rank > 0 ? 1 : -1
    const df = toFile - file > 0 ? 1 : -1
    for (let r = rank + dr, f = file + df; r !== toRank; r += dr, f += df) {
      if (this.get(toSquare(r, f))) return false
    }
    return true
  }

  private rookMove(from: Square, to: Square) {
    const [rank, file] = toRF(from)
    const [toRank, toFile] = toRF(to)
    if (rank !== toRank && file !== toFile) return false
    if (rank === toRank) {
      const df = toFile - file > 0 ? 1 : -1
      for (let f = file + df; f !== toFile; f += df) {
        if (this.get(toSquare(rank, f))) return false
      }
    } else {
      const dr = toRank - rank > 0 ? 1 : -1
      for (let r = rank + dr; r !== toRank; r += dr) {
        if (this.get(toSquare(r, file))) return false
      }
    }
    return true
  }

  private queenMove(from: Square, to: Square) {
    return this.bishopMove(from, to) || this.rookMove(from, to)
  }

  private kingMove(from: Square, to: Square) {
    const [rank, file] = toRF(from)
    const [toRank, toFile] = toRF(to)
    const dr = Math.abs(toRank - rank)
    const df = Math.abs(toFile - file)
    return dr <= 1 && df <= 1
  }
}
