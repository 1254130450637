import {useState} from 'react'
import {v4} from 'uuid'
import {UserProfile} from '../repos/userRepo'
import {makeUsername} from '../usernameMaker'

export function useLocalStorageState<T>(key: string, initialState: T | (() => T)): [T, (newValue: T) => void] {
  const [state, setState] = useState<T>(() => {
    const storedState = localStorage.getItem(key)
    if (storedState) {
      return JSON.parse(storedState)
    }
    const newState = typeof initialState === 'function' ? (initialState as () => T)() : initialState
    localStorage.setItem(key, JSON.stringify(newState))
    return newState
  })

  function setAndStore(newValue: T) {
    setState(newValue)
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [state, setAndStore]
}

export function createProfile(): UserProfile {
  return {
    userId: 'random-' + v4(),
    info: {
      username: makeUsername()
    }
  }
}

